import Constants from '@/constants';
import { useJournalYearsStore } from '@/stores/definitions/journal-years';
import { useMoment } from '@/helpers/moment';
import type { JournalYear } from '@/api/interfaces/accounting/journal-year';

export const useJournalYear = () => {
  const journalYearsStore = useJournalYearsStore();
  const moment = useMoment();

  function getJournalYear(date: string) {
    return journalYearsStore.entities.find(
      (journalYear): journalYear is JournalYear => moment(date).isBetween(
        journalYear.financial_year_start,
        journalYear.financial_year_end,
        undefined,
        '[]',
      ),
    ) || null;
  }

  function earliestBookingDate() {
    if (!journalYearsStore.entities || journalYearsStore.entities.length === 0) {
      return moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
    }

    const earliestJournalYearIndex = journalYearsStore.entities.findIndex((journalYear) => !journalYear.closed);
    const earliestJournalYear = journalYearsStore.entities[earliestJournalYearIndex];
    if (earliestJournalYearIndex && earliestJournalYearIndex > 0) {
      return earliestJournalYear.no_booking_before || earliestJournalYear.financial_year_start;
    }

    const earliestJournalYearIsCurrentYear = moment().isSameOrAfter(earliestJournalYear.financial_year_start)
      && moment().isSameOrBefore(earliestJournalYear.financial_year_end);
    if (!earliestJournalYearIsCurrentYear) {
      return earliestJournalYear.no_booking_before || earliestJournalYear.financial_year_start;
    }
    return moment(earliestJournalYear.financial_year_start).subtract(1, 'year').format('YYYY-MM-DD');
  }

  function isBookingDateValid(date: string) {
    if (!date || !moment(date).isValid()) {
      return false;
    }

    const bookingYear = journalYearsStore.entities.find(
      (journalYear) => moment(date).isSameOrAfter(journalYear.financial_year_start, 'day')
        && moment(date).isSameOrBefore(journalYear.financial_year_end, 'day'),
    );

    if (!bookingYear) {
      return moment(date).isSameOrAfter(moment(earliestBookingDate()), 'day')
        && moment(date).isSameOrBefore(Constants.LAST_POSSIBLE_INVOICE_DATE, 'day');
    }
    if (bookingYear.closed) {
      return false;
    }
    if (bookingYear.no_booking_before) {
      return moment(date).isSameOrAfter(bookingYear.no_booking_before, 'day');
    }
    return true;
  }

  return {
    getJournalYear,
    isBookingDateValid,
    earliestBookingDate,
  };
};
