import accountPlan from '@/store/modules/accounting/account-plan';
import accountStatement from '@/store/modules/accounting/account-statement';
import actions from '@/store/modules/accounting/actions';
import balanceSheet from '@/store/modules/accounting/balance-sheet';
import bookingAccount from '@/store/modules/accounting/booking-account';
import classification from '@/store/modules/accounting/classification';
import getters from '@/store/modules/accounting/getters';
import incomeStatement from '@/store/modules/accounting/income-statement';
import journal from '@/store/modules/accounting/journal';
import keyFigures from '@/store/modules/accounting/key-figures';
import mutations from '@/store/modules/accounting/mutations';
import opList from '@/store/modules/accounting/op-list';
import openingBalance from '@/store/modules/accounting/opening-balance';
import preset from '@/store/modules/accounting/preset';
import initialState from '@/store/modules/accounting/state';

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations,
  actions,
  getters,
  modules: {
    accountPlan,
    accountStatement,
    balanceSheet,
    bookingAccount,
    classification,
    incomeStatement,
    journal,
    keyFigures,
    openingBalance,
    opList,
    preset,
  },
};
